import Footer from "../Footer";
import Header from "../Header";

const AuthLayout = ({pageName, children }) => {
  return (
    <>
      <Header pageName={pageName} />
      {children}
      <Footer />
    </>
  );
};
export default AuthLayout;
