const ServicesPage = () => {
    return (
<>
<body>
  

  

    <div className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-6 col-lg-3" >
            <div className="box-feature mb-4">
              <span className="flaticon-house mb-4 d-block"></span>
              <h3 className="text-black mb-3 font-weight-bold">
                Quality Properties
              </h3>
              <p className="text-black-50">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
              <p><a href="#" className="learn-more">Read more</a></p>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box-feature mb-4">
              <span className="flaticon-house-2 mb-4 d-block-3"></span>
              <h3 className="text-black mb-3 font-weight-bold">Top Rated Agent</h3>
              <p className="text-black-50">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
              <p><a href="#" className="learn-more">Read more</a></p>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box-feature mb-4">
              <span className="flaticon-building mb-4 d-block"></span>
              <h3 className="text-black mb-3 font-weight-bold">
                Property for Sale
              </h3>
              <p className="text-black-50">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
              <p><a href="#" className="learn-more">Read more</a></p>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box-feature mb-4">
              <span className="flaticon-house-3 mb-4 d-block-1"></span>
              <h3 className="text-black mb-3 font-weight-bold">House for Sale</h3>
              <p className="text-black-50">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
              <p><a href="#" className="learn-more">Read more</a></p>
            </div>
          </div>

          <div className="col-6 col-lg-3">
            <div className="box-feature mb-4">
              <span className="flaticon-house-4 mb-4 d-block"></span>
              <h3 className="text-black mb-3 font-weight-bold">
                Quality Properties
              </h3>
              <p className="text-black-50">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
              <p><a href="#" className="learn-more">Read more</a></p>
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="box-feature mb-4">
              <span className="flaticon-building mb-4 d-block-3"></span>
              <h3 className="text-black mb-3 font-weight-bold">Top Rated Agent</h3>
              <p className="text-black-50">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
              <p><a href="#" className="learn-more">Read more</a></p>
            </div>
          </div>
          <div className="col-6 col-lg-3" >
            <div className="box-feature mb-4">
              <span className="flaticon-house mb-4 d-block"></span>
              <h3 className="text-black mb-3 font-weight-bold">
                Property for Sale
              </h3>
              <p className="text-black-50">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
              <p><a href="#" className="learn-more">Read more</a></p>
            </div>
          </div>
          <div className="col-6 col-lg-3" >
            <div className="box-feature mb-4">
              <span className="flaticon-house-1 mb-4 d-block-1"></span>
              <h3 className="text-black mb-3 font-weight-bold">House for Sale</h3>
              <p className="text-black-50">
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts.
              </p>
              <p><a href="#" className="learn-more">Read more</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="section sec-testimonials">
      <div className="container">
        <div className="row mb-5 align-items-center">
          <div className="col-md-6">
            <h2 className="font-weight-bold heading text-primary mb-4 mb-md-0">
              Customer Says
            </h2>
          </div>
          <div className="col-md-6 text-md-end">
            <div id="testimonial-nav">
              <span className="prev" data-controls="prev">Prev</span>

              <span className="next" data-controls="next">Next</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4"></div>
        </div>
        <div className="testimonial-slider-wrap">
          <div className="testimonial-slider">
            <div className="item">
              <div className="testimonial">
                <img
                  src="images/person_1-min.jpg"
                  alt="Image"
                  className="img-fluid rounded-circle w-25 mb-4"
                />
                <div className="rate">
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                </div>
                <h3 className="h5 text-primary mb-4">James Smith</h3>
                <blockquote>
                  <p>
                    &ldquo;Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts. Separated they live in Bookmarksgrove right at the
                    coast of the Semantics, a large language ocean.&rdquo;
                  </p>
                </blockquote>
                <p className="text-black-50">Designer, Co-founder</p>
              </div>
            </div>

            <div className="item">
              <div className="testimonial">
                <img
                  src="images/person_2-min.jpg"
                  alt="Image"
                  className="img-fluid rounded-circle w-25 mb-4"
                />
                <div className="rate">
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                </div>
                <h3 className="h5 text-primary mb-4">Mike Houston</h3>
                <blockquote>
                  <p>
                    &ldquo;Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts. Separated they live in Bookmarksgrove right at the
                    coast of the Semantics, a large language ocean.&rdquo;
                  </p>
                </blockquote>
                <p className="text-black-50">Designer, Co-founder</p>
              </div>
            </div>

            <div className="item">
              <div className="testimonial">
                <img
                  src="images/person_3-min.jpg"
                  alt="Image"
                  className="img-fluid rounded-circle w-25 mb-4"
                />
                <div className="rate">
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                </div>
                <h3 className="h5 text-primary mb-4">Cameron Webster</h3>
                <blockquote>
                  <p>
                    &ldquo;Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts. Separated they live in Bookmarksgrove right at the
                    coast of the Semantics, a large language ocean.&rdquo;
                  </p>
                </blockquote>
                <p className="text-black-50">Designer, Co-founder</p>
              </div>
            </div>

            <div className="item">
              <div className="testimonial">
                <img
                  src="images/person_4-min.jpg"
                  alt="Image"
                  className="img-fluid rounded-circle w-25 mb-4"
                />
                <div className="rate">
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                  <span className="icon-star text-warning"></span>
                </div>
                <h3 className="h5 text-primary mb-4">Dave Smith</h3>
                <blockquote>
                  <p>
                    &ldquo;Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts. Separated they live in Bookmarksgrove right at the
                    coast of the Semantics, a large language ocean.&rdquo;
                  </p>
                </blockquote>
                <p className="text-black-50">Designer, Co-founder</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </body>

</>
    )
}
export default ServicesPage