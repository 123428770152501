import { faRupee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { getGraphDetailsApi } from "../api";

const DummyGraph = ({ id }) => {
  // Dummy data
  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) graphDetails();
  }, [id]);

  const graphDetails = async () => {
    try {
      const res = await getGraphDetailsApi(id);
      if (res?.status == 200) {
        setData(res?.data?.data);
      }
    } catch (err) {
      throw err;
    }
  };

  // const data = [
  //   { year: 2018, price: 100000 },
  //   { year: 2019, price: 120000 },
  //   { year: 2020, price: 115000 },
  //   { year: 2021, price: 140000 },
  //   { year: 2022, price: 160000 },
  // ];

  // Graph dimensions
  const width = window.innerWidth <= 768 ? 370 : 900;
  const height = 250;
  const padding = 40;

  // Calculate scales
  const xScale = (width - padding * 2) / (data.length - 1);
  const yScale =
    (height - padding * 2) /
    (Math.max(...data.map((d) => d.price)) -
      Math.min(...data.map((d) => d.price)));

  // Generate wave path using Bézier curves
  const pathData = data
    .map((d, i) => {
      const x = i * xScale + padding;
      const y =
        height -
        (d.price - Math.min(...data.map((d) => d.price))) * yScale -
        padding;
      return { x, y };
    })
    .reduce((acc, point, i, arr) => {
      if (i === 0) {
        return `M${point.x},${point.y}`;
      }
      const prevPoint = arr[i - 1];
      const cp1x = prevPoint.x + (point.x - prevPoint.x) / 2;
      const cp1y = prevPoint.y;
      const cp2x = point.x - (point.x - prevPoint.x) / 2;
      const cp2y = point.y;
      return `${acc} C${cp1x},${cp1y} ${cp2x},${cp2y} ${point.x},${point.y}`;
    }, "");

  // Generate area path
  const areaPathData = `${pathData} L${width - padding},${
    height - padding
  } L${padding},${height - padding} Z`;

  // Number of ticks
  const numTicks = 5;

  return (
    <div className="">
      {data && data.length > 0 && (
        <>
          {" "}
          <p className="text-lg font-bold text-[#005555] mt-24  font-semibold">
            AVG PROPERTY RATE
          </p>
          <svg width={width} height={height} className="-mt-4">
            {/* X and Y axes */}
            <line
              x1={padding}
              y1={height - padding}
              x2={width - padding}
              y2={height - padding}
              stroke="lightgray"
            />
            <line
              x1={padding}
              y1={padding}
              x2={padding}
              y2={height - padding}
              stroke="lightgray"
            />

            {/* Shaded area */}
            <path d={areaPathData} fill="rgba(0, 128, 0, 0.2)" />

            {/* Data line */}
            <path
              d={pathData}
              fill="none"
              stroke="green"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />

            {/* X-axis labels */}
            {data.map((d, i) => (
              <text
                key={i}
                x={i * xScale + padding}
                y={height - padding / 2}
                textAnchor="middle"
                fontSize="8"
              >
                {d.year}
              </text>
            ))}

            {/* Y-axis labels and grid lines */}
            {[...Array(numTicks)].map((_, tick) => {
              const yPosition =
                height -
                (tick * (height - padding * 2)) / (numTicks - 1) -
                padding;
              const priceValue = Math.round(
                ((tick *
                  (Math.max(...data.map((d) => d.price)) -
                    Math.min(...data.map((d) => d.price)))) /
                  (numTicks - 1) +
                  Math.min(...data.map((d) => d.price))) /
                  1000
              );

              return (
                <React.Fragment key={tick}>
                  <text
                    x={padding / 2}
                    y={yPosition}
                    textAnchor="middle"
                    fontSize="9"
                    dy="0.3em"
                  >
                    <FontAwesomeIcon icon={faRupee} />{" "}
                    {priceValue && `${priceValue}k`}
                  </text>
                  <line
                    x1={padding}
                    y1={yPosition}
                    x2={width - padding}
                    y2={yPosition}
                    stroke="lightgray"
                    strokeDasharray="4 2"
                  />
                </React.Fragment>
              );
            })}
          </svg>{" "}
        </>
      )}
    </div>
  );
};

export default DummyGraph;
