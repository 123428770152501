import axios from 'axios'
import { API_BASE_URL } from "../config";


// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // replace with your API base URL
})

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['token'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const { response } = error
    if (response && response.status === 401) {
      // Redirect to the login page
      window.location.href = '/login' // or useHistory().push('/login') if inside a component
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
