import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { resetPassApi } from "../../api";
import toast from "toastr"

const ResetPassPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const email = localStorage.getItem("email");
  const [data, setData] = useState({ confirmPassword: "", password: "" });
  const [errors, setErrors] = useState({});
  const [showRegisterPassword, setshowRegisterPassword] = useState(false);
  const [showRegisterConfirmPassword, setshowRegisterConfirmPassword] =
    useState(false);

  const handlePassword = () => setshowRegisterPassword(!showRegisterPassword);

  const handleConfirmPassword = () =>
    setshowRegisterConfirmPassword(!showRegisterConfirmPassword);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({});
  };

  const validate = () => {
    const newErrors = {};
    if (!data.password.trim()) {
      newErrors.password = "Password is required";
    } else if (data.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    } else if (!/[A-Z]/.test(data.password)) {
      newErrors.password = "Password must contain at least one capital letter";
    } else if (!/[0-9]/.test(data.password)) {
      newErrors.password = "Password must contain at least one digit";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.password)) {
      newErrors.password =
        "Password must contain at least one special character";
    } else if (/\s/.test(data.password)) {
      newErrors.password = "Password must not contain spaces";
    } else if (!data.confirmPassword.trim()) {
      newErrors.confirmPassword = "Confirm passowrd is required";
    } else if (data.password !== data.confirmPassword) {
      newErrors.confirmPassword =
        "Confirm passowrd should be match to password";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        const res = await resetPassApi({
          email: email,
          password: data?.password,
        });
        if (res?.status == 200) {
          console.log("Login successful:", data);
          localStorage.removeItem("email");
          toast.success(res?.data?.message)
          navigate("/login");
        } else {
          toast.error(res?.data?.message)
        }
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="bg-[#005555] w-full h-screen flex flex-col justify-center py-8 px-8 align-center items-center">
      <div className="w-[350px] md:w-[400px] lg:w-[500px] bg-white rounded-lg shadow py-2 md:px-6 md:py-6">
        <h2 className="text-2xl text-black text-center w-full font-bold mt-4">
          Reset Your Password
        </h2>
        <form onSubmit={handleSubmit} className="w-full h-full px-8 py-8">
          <div className="flex flex-col text-md mb-2 relative">
            <label className="text-black text-sm mb-2">New Password</label>
            <input
              type={showRegisterPassword ? "text" : "password"}
              name="password"
              value={data.password}
              onChange={handleChange}
              className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
              placeholder="New Password"
            />
            <span
              className="absolute right-3 top-9 cursor-pointer"
              onClick={handlePassword}
            >
              <FontAwesomeIcon
                className="text-black "
                icon={showRegisterPassword ? faEyeSlash : faEye}
              />
            </span>
            {errors.password && (
              <p className="text-red-500 text-xs">{errors.password}</p>
            )}
          </div>
          <div className="flex flex-col text-md mb-2 relative">
            <label className="text-black text-sm mb-2">Confirm Password</label>
            <input
              type={showRegisterConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={data.confirmPassword}
              onChange={handleChange}
              className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
              placeholder="Confirm Password"
            />
            <span
              className="absolute right-3 top-9 cursor-pointer"
              onClick={handleConfirmPassword}
            >
              <FontAwesomeIcon
                className="text-black "
                icon={showRegisterConfirmPassword ? faEyeSlash : faEye}
              />
            </span>
            {errors.confirmPassword && (
              <p className="text-red-500 text-xs">{errors.confirmPassword}</p>
            )}
          </div>

          <div className="w-full flex item-center justify-center mt-8">
            <button
              type="submit"
              className="bg-[#005555] px-2 py-2 text-white rounded-md w-full"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassPage;
