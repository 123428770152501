import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const TermsAndConditionsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="cursor-pointer" onClick={() => navigate(-1)}>
          <p className="font-bold text-xl mb-4">
            <FontAwesomeIcon icon={faArrowLeft} />
          </p>
        </div>
        <h2 className="font-bold text-[#005555]">Terms & Conditions</h2>
        <div className=" mt-4">
          <p className="text-[#005555] font-bold text-lg">
            {" "}
            {/* Mission Statement: */}
          </p>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum, arcu ut pharetra fermentum, erat felis dignissim odio, vitae
            tempus massa libero ac mauris. Proin vehicula nibh a augue
            tincidunt, in laoreet sem ullamcorper. Nulla facilisi. Sed vitae
            lectus at est congue tincidunt. Aenean non justo sed elit malesuada
            ultrices. Duis in malesuada velit, at fermentum lorem. Sed
            convallis, mauris nec vestibulum blandit, eros sapien fermentum
            lectus, id cursus nulla justo sit amet eros.
          </span>
        </div>
        <div className="mt-4">
          {/* <p className="text-[#005555] font-bold text-lg">
            What you can expect from US:
          </p> */}
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum, arcu ut pharetra fermentum, erat felis dignissim odio, vitae
            tempus massa libero ac mauris. Proin vehicula nibh a augue
            tincidunt, in laoreet sem ullamcorper. Nulla facilisi. Sed vitae
            lectus at est congue tincidunt. Aenean non justo sed elit malesuada
            ultrices. Duis in malesuada velit, at fermentum lorem. Sed
            convallis, mauris nec vestibulum blandit, eros sapien fermentum
            lectus, id cursus nulla justo sit amet eros.
          </span>
        </div>
        <div className="mt-4">
          {/* <p className="text-[#005555] font-bold text-lg">
            What you can expect from US:
          </p> */}
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum, arcu ut pharetra fermentum, erat felis dignissim odio, vitae
            tempus massa libero ac mauris. Proin vehicula nibh a augue
            tincidunt, in laoreet sem ullamcorper. Nulla facilisi. Sed vitae
            lectus at est congue tincidunt. Aenean non justo sed elit malesuada
            ultrices. Duis in malesuada velit, at fermentum lorem. Sed
            convallis, mauris nec vestibulum blandit, eros sapien fermentum
            lectus, id cursus nulla justo sit amet eros.
          </span>
        </div>{" "}
        <div className="mt-4">
          {/* <p className="text-[#005555] font-bold text-lg">
            What you can expect from US:
          </p> */}
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum, arcu ut pharetra fermentum, erat felis dignissim odio, vitae
            tempus massa libero ac mauris. Proin vehicula nibh a augue
            tincidunt, in laoreet sem ullamcorper. Nulla facilisi. Sed vitae
            lectus at est congue tincidunt. Aenean non justo sed elit malesuada
            ultrices. Duis in malesuada velit, at fermentum lorem. Sed
            convallis, mauris nec vestibulum blandit, eros sapien fermentum
            lectus, id cursus nulla justo sit amet eros.
          </span>
        </div>{" "}
        <div className="mt-4">
          {/* <p className="text-[#005555] font-bold text-lg">
            What you can expect from US:
          </p> */}
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum, arcu ut pharetra fermentum, erat felis dignissim odio, vitae
            tempus massa libero ac mauris. Proin vehicula nibh a augue
            tincidunt, in laoreet sem ullamcorper. Nulla facilisi. Sed vitae
            lectus at est congue tincidunt. Aenean non justo sed elit malesuada
            ultrices. Duis in malesuada velit, at fermentum lorem. Sed
            convallis, mauris nec vestibulum blandit, eros sapien fermentum
            lectus, id cursus nulla justo sit amet eros.
          </span>
        </div>{" "}
        <div className="mt-4">
          {/* <p className="text-[#005555] font-bold text-lg">
            What you can expect from US:
          </p> */}
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum, arcu ut pharetra fermentum, erat felis dignissim odio, vitae
            tempus massa libero ac mauris. Proin vehicula nibh a augue
            tincidunt, in laoreet sem ullamcorper. Nulla facilisi. Sed vitae
            lectus at est congue tincidunt. Aenean non justo sed elit malesuada
            ultrices. Duis in malesuada velit, at fermentum lorem. Sed
            convallis, mauris nec vestibulum blandit, eros sapien fermentum
            lectus, id cursus nulla justo sit amet eros.
          </span>
        </div>
        <div className="mt-4">
          {/* <p className="text-[#005555] font-bold text-lg">
            What you can expect from US:
          </p> */}
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum, arcu ut pharetra fermentum, erat felis dignissim odio, vitae
            tempus massa libero ac mauris. Proin vehicula nibh a augue
            tincidunt, in laoreet sem ullamcorper. Nulla facilisi. Sed vitae
            lectus at est congue tincidunt. Aenean non justo sed elit malesuada
            ultrices. Duis in malesuada velit, at fermentum lorem. Sed
            convallis, mauris nec vestibulum blandit, eros sapien fermentum
            lectus, id cursus nulla justo sit amet eros.
          </span>
        </div>
        <div className="mt-4">
          {/* <p className="text-[#005555] font-bold text-lg">
            What you can expect from US:
          </p> */}
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum, arcu ut pharetra fermentum, erat felis dignissim odio, vitae
            tempus massa libero ac mauris. Proin vehicula nibh a augue
            tincidunt, in laoreet sem ullamcorper. Nulla facilisi. Sed vitae
            lectus at est congue tincidunt. Aenean non justo sed elit malesuada
            ultrices. Duis in malesuada velit, at fermentum lorem. Sed
            convallis, mauris nec vestibulum blandit, eros sapien fermentum
            lectus, id cursus nulla justo sit amet eros.
          </span>
        </div>
        <div className="mt-4">
          {/* <p className="text-[#005555] font-bold text-lg">
            What you can expect from US:
          </p> */}
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            dictum, arcu ut pharetra fermentum, erat felis dignissim odio, vitae
            tempus massa libero ac mauris. Proin vehicula nibh a augue
            tincidunt, in laoreet sem ullamcorper. Nulla facilisi. Sed vitae
            lectus at est congue tincidunt. Aenean non justo sed elit malesuada
            ultrices. Duis in malesuada velit, at fermentum lorem. Sed
            convallis, mauris nec vestibulum blandit, eros sapien fermentum
            lectus, id cursus nulla justo sit amet eros.
          </span>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditionsPage;
