import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { forgotPasswordApi } from "../../api";
import toast from "toastr";

const ForgotPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
    setErrors("");
  };

  const validate = () => {
    let newErrors = "";
    if (!email.trim()) {
      newErrors = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors = "Please enter a valid email address";
    }
    setErrors(newErrors);
    return newErrors === "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        const res = await forgotPasswordApi({ email: email });
        if (res?.status == 200) {
          localStorage.setItem("email", email);
          toast.success(res?.data?.message);
          navigate("/account-validate");
        } else {
          toast.error(res?.data?.message);
        }
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="bg-[#005555]  w-full h-screen flex flex-col justify-center py-8 px-8 align-center items-center">
      <div className="w-[350px] md:w-[400px] lg:w-[500px] bg-white rounded-lg shadow py-2 md:px-6 md:py-6">
        <div className="cursor-pointer" onClick={() => navigate(-1)}>
          <p className="font-bold text-xl ml-8 mb-4">
            <FontAwesomeIcon icon={faArrowLeft} />
          </p>
        </div>
        <h2 className="text-2xl text-black text-center w-full font-bold mt-4">
          Forgot Password
        </h2>
        <form onSubmit={handleSubmit} className="w-full h-full px-8 py-8">
          <div className="flex flex-col text-md mb-2">
            <label className="text-black text-sm mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              className="w-full text-sm px-2 py-2 bg-gray-200 border-none rounded-md"
              placeholder="Email"
            />
            {errors && <p className="text-red-500 text-xs">{errors}</p>}
          </div>
          <div className="w-full flex item-center justify-center mt-8">
            <button
              type="submit"
              className="cursor-pointer bg-[#005555] px-2 py-2 text-white rounded-md w-full"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPage;
