import React from "react";

const VerifyEmailModal = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className="z-50 fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center px-4">
      <div className="bg-white p-8 rounded-lg shadow-lg w-[500px] relative">
        <button
          className="absolute top-2 right-2 font-bold text-[20px] text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default VerifyEmailModal;
