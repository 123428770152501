import { useState } from "react";
import { cantactUsApi } from "../../api";
import toast from "toastr";
import { useNavigate } from "react-router-dom";

const ContactUsPage = () => {
  toast.options = { preventDuplicates: true };
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({});
  };

  const validate1 = () => {
    const newErrors = {};
    if (!data?.name) {
      newErrors.name = "Name is required";
    } else if (!data?.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data?.email)) {
      newErrors.email = "Please enter a valid email address";
    } else if (!data?.subject) {
      newErrors.subject = "Subject is required";
    } else if (!data?.message) {
      newErrors.message = "Message is required";
    } else if (!data?.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
    } else if (!/^\d{10}$/.test(data?.mobileNumber)) {
      newErrors.mobileNumber = "Please enter a valid Mobile number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate1()) {
        const obj = {
          name: data?.name,
          email: data?.email,
          countryCode: "+91",
          mobile: data?.mobileNumber,
          message: data?.message,
        };
        setLoader(true);
        const res = await cantactUsApi(obj);
        if (res?.status == 200) {
          toast.success(res?.data?.message);
          setLoader(false);
        } else if (res?.data?.error_description?.statusCode == 401) {
          toast.error(res?.data?.error_description?.message);
          setLoader(false);
          localStorage.clear();
          navigate("/login");
        } else {
          setLoader(false);
        }
      }
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <div>
      <p className="text-3xl  text-primary font-bold text-center w-full ">
        {" "}
        Contact Us
      </p>

      <div className="section -mt-12">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <div className="contact-info">
                <div className="address mt-2">
                  <i className="icon-room"></i>
                  <h4 className="mb-2">Location:</h4>
                  <p>
                  Plot No. 310 Hyderabad,
                    <br />
                    Telangana 500033
                  </p>
                </div>

                {/* <div className="open-hours mt-4">
                  <i className="icon-clock-o"></i>
                  <h4 className="mb-2">Open Hours:</h4>
                  <p>
                    Sunday-Friday:
                    <br />
                    11:00 AM - 23:00 PM
                  </p>
                </div> */}

                <div className="email mt-4">
                  <i className="icon-envelope"></i>
                  <h4 className="mb-2">Email:</h4>
                  <p>info@sharebric.com</p>
                </div>

                <div className="phone mt-4">
                  <i className="icon-phone"></i>
                  <h4 className="mb-2">Call:</h4>
                  <p>+91 0000000000</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <form>
                <div className="row">
                  <div className="col-6 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      name="name"
                      value={data?.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-xs">{errors.name}</p>
                    )}
                  </div>

                  <div className="col-6 mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      name="email"
                      value={data?.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs">{errors.email}</p>
                    )}
                  </div>

                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control w-full"
                      placeholder="Your Mobile Number"
                      name="mobileNumber"
                      value={data?.mobileNumber}
                      onChange={handleChange}
                    />
                    {errors.mobileNumber && (
                      <p className="text-red-500 text-xs">
                        {errors.mobileNumber}
                      </p>
                    )}
                  </div>

                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      name="subject"
                      value={data?.subject}
                      onChange={handleChange}
                    />
                    {errors.subject && (
                      <p className="text-red-500 text-xs">{errors.subject}</p>
                    )}
                  </div>

                  <div className="col-12 mb-3">
                    <textarea
                      name="message"
                      id=""
                      cols="30"
                      rows="7"
                      className="form-control"
                      placeholder="Message"
                      value={data?.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  {errors.message && (
                    <p className="text-red-500 text-xs">{errors.message}</p>
                  )}

                  <div className="col-12 mb-8">
                    <button
                      disabled={loader ? true : false}
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-primary"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUsPage;
