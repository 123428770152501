import OTPInput, { ResendOTP } from "otp-input-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ResendOTPApi, verifyOtpApi } from "../../api";
import toast from "toastr";

const AccountValidate = (props) => {
  toast.options = { preventDuplicates: true };
  const [otp, setOtp] = useState("");
  const email = localStorage.getItem("email");
  const navigate = useNavigate();
  const handleSubmit = async () => {
    try {
      const res = await ResendOTPApi({ email: email });
      if (res?.data) {
        navigate("/reset-pass");
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (err) {
      throw err;
    }
  };

  const matchOtp = async () => {
    try {
      const res = await verifyOtpApi({
        email: email,
        otp: otp,
      });
      if (res?.status == 200) {
        toast.success(res?.data?.message);
        navigate("/reset-pass");
      } else {
        toast.error(res?.data?.message);
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <div className="bg-[#005555] w-full h-screen flex flex-col justify-center py-8 px-8 align-center items-center">
        <div className="w-[350px] md:w-[400px] lg:w-[500px] bg-white rounded-lg shadow py-2 md:px-6 md:py-6">
          <h2 className="text-2xl text-black text-center w-full font-bold mt-4">
            Account validation
          </h2>
          <p className="text-center font-samibold mt-6">
            Please enter the 6 digit code we send to <b>{email}</b>
          </p>

          <form className="mt-6">
            <div className="flex  w-full justify-center  px-4 mb-3 align-center items-center">
              <OTPInput
                value={otp}
                onChange={setOtp}
                inputClassName="otp-input border"
                className="otp-input "
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                secure
              />
            </div>

            <div className="flex flex-col px-4">
              <p className="text-[#005555] self-end">Didn't get a code?</p>
              <ResendOTP
                className="cursor-pointer"
                onResendClick={(e) => handleSubmit(e, false)}
              />
            </div>
            <div className="px-4 mb-4 w-full flex item-center justify-center mt-8">
              <button
                type="button"
                onClick={matchOtp}
                className="bg-[#005555] px-2 py-2 text-white rounded-md w-full"
              >
                Verify Otp
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccountValidate;
